import type { CALC_METHOD } from '@/imports/@enums/calcMethodCode.enum'

export enum FILE_IMPORT_TYPE {
  CORPORATE_DATA_SOURCE = 'CORPORATE_DATA_SOURCE',
  PRODUCT_DATA_SOURCE = 'PRODUCT_DATA_SOURCE',
  EMISSION_FACTOR = 'EMISSION_FACTOR',
  EMISSION_FACTOR_SOURCE = 'EMISSION_FACTOR_SOURCE',
  PRODUCT_LIST = 'PRODUCT_LIST',
}

export type ImportMetadata = { importType: FILE_IMPORT_TYPE; user_id: string }

export type CorporateDataSourceImportMetadata = ImportMetadata & {
  rootOrgId: string
  orgId: string
  dataSourceId: string
  calcMethodCode?: CALC_METHOD
}

export type ProductDataSourceImportMetadata = ImportMetadata & {
  rootOrgId: string
  orgId: string
  dataSourceId: string
}

export type EmissionFactorImportMetadata = ImportMetadata
export type EmissionFactorSourceImportMetadata = ImportMetadata

export type ProductListImportMetadata = {
  rootOrgId: string
  orgId: string
}

export type ImportMetadataMap = {
  [FILE_IMPORT_TYPE.CORPORATE_DATA_SOURCE]: CorporateDataSourceImportMetadata
  [FILE_IMPORT_TYPE.PRODUCT_DATA_SOURCE]: ProductDataSourceImportMetadata
  [FILE_IMPORT_TYPE.EMISSION_FACTOR]: EmissionFactorImportMetadata
  [FILE_IMPORT_TYPE.EMISSION_FACTOR_SOURCE]: EmissionFactorSourceImportMetadata
  [FILE_IMPORT_TYPE.PRODUCT_LIST]: ProductListImportMetadata
}
