import type { AxiosInstance } from 'axios'

import type { ProductsMetaQuery } from '@/imports/@types/ProductList'
import { GET_PRODUCT_LIST_URI, type GetProductListDTORes } from '@/imports/lib/DTO/products/get-products.dto'
import {
  GET_PRODUCT_LIST_CATEGORIES_URI,
  type GetProductListCategoriesDTORes,
} from '@/imports/lib/DTO/products/get-product-categories.dto'

export const useProductList = (http: AxiosInstance) => ({
  getProducts(orgId: string, productsMetaQuery: ProductsMetaQuery) {
    return http.get<GetProductListDTORes>(GET_PRODUCT_LIST_URI, {
      params: {
        orgId,
        ...productsMetaQuery,
      },
    })
  },

  getProductsCategories(orgId: string) {
    return http.get<GetProductListCategoriesDTORes>(GET_PRODUCT_LIST_CATEGORIES_URI, {
      params: {
        orgId,
      },
    })
  },
})
