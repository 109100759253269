import { isArray, intersection } from 'lodash-es'

import { useOrganizationStore } from '@/client/store/organization.pinia'
import { useUserStore } from '@/client/store/user.pinia'
import type { ACTION } from '@/imports/lib/constants/permission/permissionConstants'

/**
 * Check if a user has one or more permissions based on their role
 */
const PermissionService = {
  hasPermissions(permissions: ACTION | ACTION[]): boolean {
    const userStore = useUserStore()
    if (userStore.isSuperUser) return true

    const orgStore = useOrganizationStore()
    const currentUserRole = orgStore.userRole
    const availableRoles = orgStore.availableRoles

    if (availableRoles && currentUserRole) {
      const rolePermissions = availableRoles[currentUserRole]

      if (isArray(permissions)) {
        if (intersection(permissions, rolePermissions).length > 0) return true
      } else if (rolePermissions.includes(permissions)) return true
    }

    return false
  },
}

export default PermissionService
