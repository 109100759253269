import { PRODUCT_FOOTPRINTS_CONTROLLER } from '../controller.common-vars'

import { ValidateDTO } from '../..'
import type { PaginationMeta } from '../../generic/pagination-meta.dto'
import { SORT_ORDER } from '../../../../@enums/common.enums'

export enum CATEGORY_LIST_SORT_FIELD {
  NAME = 'name',
  TOTAL_EMISSIONS = 'totalEmissions',
  FACTOR = 'factor',
}

// Category list page
// [GET] /products/footprints/overview/categories
export const PRODUCT_CATEGORIES_OVERVIEW_SUBPATH = 'overview/categories'
export const PRODUCT_CATEGORIES_OVERVIEW_URI = `${PRODUCT_FOOTPRINTS_CONTROLLER}/${PRODUCT_CATEGORIES_OVERVIEW_SUBPATH}`

export class CategoryListDTOReq extends ValidateDTO {
  startMonth!: string

  endMonth!: string

  searchTerm?: string

  perPage!: number

  pageNo!: number

  sortBy?: CATEGORY_LIST_SORT_FIELD

  sortOrder?: SORT_ORDER
}

export type CategoryListItem = {
  name: string
  productsProduced: number
  totalEmissionsPerProduct: number
  totalEmissions: number
  totalEmissionUnit: string // changed from emissionsUnit
  factor: number
}

export type CategoryListDTORes = {
  categories: CategoryListItem[]
  meta: PaginationMeta & {
    sortBy?: CATEGORY_LIST_SORT_FIELD
    sortOrder?: SORT_ORDER
  }
}
