import { IsNumber } from 'class-validator'

import type { BaseSupplier } from '../../../@types/suppliers.types'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class GetSupplierDTO {
  @IsNumber()
  year!: number
}

export type GetSupplierDTORes = {
  supplier: BaseSupplier
}

export const GET_SUPPLIER_SUB_PATH = ':supplierId'
export const GET_SUPPLIER_PATH = `${SUPPLIERS_CONTROLLER}/${GET_SUPPLIER_SUB_PATH}`
