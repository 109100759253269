import type { AxiosInstance } from 'axios'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'

import type {
  ISICOption,
  SupplierProductDetailsResponse,
  SupplierProductFootprint,
  SupplierProductsTableDataResponse,
} from '@/imports/@types/SupplierProduct'
import type {
  RequestForProductFootprintPayload,
  RequestForProductFootprintPayloadDeprecated,
} from '@/imports/@types/Supplier.v2'
import type {
  GetProductFootprintStepsResponse,
  GetProductRequestsPayload,
  GetProductRequestsResponse,
  GetProductRespondedDataRequestsPayload,
  GetProductRespondedDataRequestsResponse,
} from '@/imports/@types/DataRequest'

import { useOrganizationStore } from '@/client/store/organization.pinia'

export const useSupplierProductsApi = (http: AxiosInstance) => ({
  getSupplierProducts({
    orgId,
    perPage,
    pageNo,
    productFootprintId,
    query = '',
  }: {
    orgId: string | undefined
    perPage: number
    pageNo: number
    productFootprintId?: string
    query?: string
  }) {
    return http.get<SupplierProductsTableDataResponse>('/supplier-product-footprints', {
      params: {
        orgId,
        perPage,
        pageNo,
        productFootprintId,
        query,
      },
    })
  },

  getSupplierProduct(productFootprintId: string) {
    const orgId = useOrganizationStore().id || ''

    return http.get<SupplierProductsTableDataResponse>('/supplier-product-footprints', {
      params: {
        orgId,
        productFootprintId,
      },
    })
  },

  createSupplierProduct(payload: {
    orgId: string | undefined
    productFootprint: SupplierProductFootprint
    isDraft: boolean
    productFootprintRequestId?: string
  }) {
    return http.post<ApiResponseInner<SupplierProductDetailsResponse>>('/supplier-product-footprints', payload)
  },

  updateSupplierProduct(
    payload: {
      orgId: string | undefined
      productFootprint: SupplierProductFootprint
      isDraft: boolean
    },
    productFootprintId: string,
  ) {
    return http.patch<ApiResponseInner<SupplierProductDetailsResponse>>(
      `supplier-product-footprints/${productFootprintId}`,
      payload,
    )
  },

  saveProductFootprintChanges(id: string, payload: { productFootprint: SupplierProductFootprint; isDraft: boolean }) {
    const orgId = useOrganizationStore().id || ''

    return http.patch<ApiResponseInner<SupplierProductDetailsResponse>>(`supplier-product-footprints/${id}`, payload, {
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  deleteSupplierProduct(params: { orgId?: string }, productFootprintId: string) {
    return http.delete<ApiResponseInner<never>>(`/supplier-product-footprints/${productFootprintId}`, {
      params,
    })
  },

  getOptions({ optionsType, query, orgId }: { optionsType: 'ISIC' | 'CPC'; query: string; orgId: string }) {
    return http.get<ApiResponseInner<ISICOption[]>>('/supplier/getOptions', {
      params: {
        optionsType,
        query,
        orgId,
      },
    })
  },

  getIsicOptions() {
    const orgId = useOrganizationStore().id || ''

    return http.get<ApiResponseInner<ISICOption[]>>('/supplier/getOptions', {
      params: {
        optionsType: 'ISIC',
        query: '',
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  sendRequestForProductFootprintDeprecated(payload: RequestForProductFootprintPayloadDeprecated) {
    return http.post<{ success: boolean }>('/supplier-product-footprints/requests', payload)
  },

  sendRequestForProductFootprint(payload: RequestForProductFootprintPayload) {
    return http.post<{ success: boolean }>('/supplier-product-footprints/requests-v2', payload)
  },

  updateProductFootprintRequestNoData({
    orgId,
    hasNoData,
    productFootprintRequestId,
  }: {
    orgId: string
    hasNoData: boolean
    productFootprintRequestId: string
  }) {
    return http.patch<{ success: boolean }>(
      `/supplier-product-footprints/requests/${productFootprintRequestId}/no-data`,
      {
        orgId,
        hasNoData,
      },
    )
  },

  finalizeSupplierProductFootprintRequest({
    orgId,
    productFootprintRequestId,
    supplierProductFootprintId,
  }: {
    orgId: string
    productFootprintRequestId: string
    supplierProductFootprintId: string
  }) {
    return http.patch<{ success: boolean }>(
      `/supplier-product-footprints/requests/${productFootprintRequestId}/finalize`,
      {
        orgId,
        supplierProductFootprintId,
      },
    )
  },

  getProductRequests(params: GetProductRequestsPayload) {
    return http.get<GetProductRequestsResponse>('/supplier-product-footprints/requests', {
      params,
    })
  },

  getFootprintReport(params: GetProductRespondedDataRequestsPayload) {
    return http.get<GetProductRespondedDataRequestsResponse>('/supplier-product-footprints/report', {
      params,
    })
  },

  getProductFootprintStepsData() {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetProductFootprintStepsResponse>('/supplier-product-footprints/onboarding-progress', {
      params: {},
      headers: {
        'x-org-id': orgId,
      },
    })
  },
})
