// Total carbon impact
// [GET] /products/overview/metric-impact?orgId=xxx&startMonth=01-2022&endMonth=12-2022

import { PRODUCT_FOOTPRINTS_CONTROLLER } from '../controller.common-vars'

import { ValidateDTO } from '../..'

export const PRODUCT_OVERVIEW_METRIC_IMPACT_SUBPATH = 'overview/metric-impact'
export const PRODUCT_OVERVIEW_METRIC_IMPACT_URI = `${PRODUCT_FOOTPRINTS_CONTROLLER}/${PRODUCT_OVERVIEW_METRIC_IMPACT_SUBPATH}`

export class TotalProductCarbonImpactDTOReq extends ValidateDTO {
  startMonth!: string

  endMonth!: string
}

export type TotalProductCarbonImpactDTORes = {
  totalUnits: number // SUM(products_produced)
  totalProducts: number // COUNT( DISTINCT (product_code, product_name))
  totalEmissions: number
  totalEmissionUnit: string // changed from emissionsUnit
}
