// eslint-disable-next-line max-classes-per-file
import { IsString } from 'class-validator'

import type { SupplierInviteFromTokenCheck } from '../../../@types/suppliers.types'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class GetSupplierInviteDTO {
  @IsString()
  token!: string
}

export type GetSupplierInviteDTORes = {
  invite: SupplierInviteFromTokenCheck
}

export const GET_SUPPLIER_INVITE_SUB_PATH = 'invites/token/:token'
export const GET_SUPPLIER_INVITE_PATH = `${SUPPLIERS_CONTROLLER}/${GET_SUPPLIER_INVITE_SUB_PATH}`
