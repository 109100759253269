// Highest emitting product category
// [GET] /products/footprints/overview/metric-highest-category?orgId=xxx&startMonth=01-2022&endMonth=12-2022
import { PRODUCT_FOOTPRINTS_CONTROLLER } from '../controller.common-vars'

export const PRODUCT_OVERVIEW_HIGHEST_CAT_SUBPATH = 'overview/metric-highest-category'
export const PRODUCT_OVERVIEW_HIGHEST_CAT_URI = `${PRODUCT_FOOTPRINTS_CONTROLLER}/${PRODUCT_OVERVIEW_HIGHEST_CAT_SUBPATH}`

export class HighestEmittingProductCategoryDTOReq {
  startMonth!: string

  endMonth!: string
}

export type HighestEmittingProductCategoryDTORes = {
  category: string
  totalEmissions: number
  totalEmissionUnit: string // changed from emissionsUnit
}
