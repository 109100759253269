import { IsBoolean, IsNumber, IsOptional, IsString, IsUUID, ValidateIf } from 'class-validator'

import { PRODUCT_FOOTPRINTS_CONTROLLER } from '../controller.common-vars'

import { ValidateDTO } from '../..'
import type { PaginationMeta } from '../../generic/pagination-meta.dto'

import { BASIC_FACILITY_DATA_STATUS } from '@/imports/@enums/basic-facilities.enums'

// Product snapshots
// [GET] /products/footprints/:productId/facilities/:facilityId/snapshots&perPage=10&pageNo=1&startMonth=01-2022&endMonth=12-2022
export const PRODUCT_SNAPSHOT_FACILITIES_LIST_SUBPATH = ':productId/facilities/:facilityId/snapshots'
export const PRODUCT_SNAPSHOT_FACILITIES_LIST_URI = `${PRODUCT_FOOTPRINTS_CONTROLLER}/${PRODUCT_SNAPSHOT_FACILITIES_LIST_SUBPATH}`

// [GET] /products/footprints/:productId/bus/:buId/snapshots&perPage=10&pageNo=1&startMonth=01-2022&endMonth=12-2022
export const PRODUCT_SNAPSHOT_BUS_LIST_SUBPATH = ':productId/bus/:buId/snapshots'
export const PRODUCT_SNAPSHOT_BUS_LIST_URI = `${PRODUCT_FOOTPRINTS_CONTROLLER}/${PRODUCT_SNAPSHOT_BUS_LIST_SUBPATH}`

export class ProductSnapshotsDTOReq extends ValidateDTO {
  @ValidateIf(f => !f.buId)
  @IsUUID()
  facilityId?: string

  @ValidateIf(f => !f.facilityId)
  @IsUUID()
  buId?: string

  @IsString()
  productId!: string

  @IsString()
  startMonth!: string

  @IsString()
  endMonth!: string

  @IsNumber()
  perPage?: number

  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsBoolean()
  includeBasicPCFStatus?: boolean
}

export type Snapshot = {
  id: string
  validFrom: string // dateonly '2020-01-01'
  customerIdentifier: { title: string; value: string }[] | null
  startDate: string // dateonly '2020-01-01'
  endDate: string // dateonly '2020-01-01'
  productsProduced: number
  totalEmissions: number
  totalEmissionsUnit: string
  unitEmissions: number
  unitEmissionsUnit: string
}

export type ProductSnapshotsDTORes = {
  snapshots: Snapshot[]
  meta: PaginationMeta & { status?: BASIC_FACILITY_DATA_STATUS }
}
