import { IsUUID, IsNumber, IsOptional, IsString, IsEnum, IsArray } from 'class-validator'

import { ValidateDTO } from '..'

import { SORT_ORDER } from '../../../../imports/@enums/common.enums'

import { PRODUCTS_CONTROLLER } from './controller.common-vars'

export enum PRODUCT_SORT_FIELD {
  NAME = 'name',
  DESCRIPTION = 'description',
  CATEGORY = 'category',
  DECLARED_UNIT = 'declared_unit',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  PRODUCT_KEY = 'product_key',
}

export const GET_PRODUCT_LIST_SUBPATH = 'listing'
export const GET_PRODUCT_LIST_URI = `${PRODUCTS_CONTROLLER}/${GET_PRODUCT_LIST_SUBPATH}`

export class GetProductListDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsNumber()
  perPage!: number

  @IsNumber()
  pageNo!: number

  @IsString()
  @IsOptional()
  query?: string

  @IsEnum(SORT_ORDER)
  @IsOptional()
  sortOrder?: string

  @IsEnum(PRODUCT_SORT_FIELD)
  @IsOptional()
  sortField?: string

  @IsArray()
  @IsOptional()
  category?: string[]
}

export type GetProductListDTORes = {
  products: {
    id: string
    orgId: string
    productKey: string
    name: string
    description: string
    category: string
    declaredUnit: string
    updatedAt: string
  }[]
  meta: {
    pageNo: number
    perPage: number
    sortField: PRODUCT_SORT_FIELD
    sortOrder: SORT_ORDER
    totalRecords: number
  }
}
