import type { AxiosInstance } from 'axios'

import { POST_DATA_IMPORTER_JWT_TOKEN_URI } from '@/imports/lib/DTO/one-schema-upload/auth/post-jwt-token.dto'

import type { FILE_IMPORT_TYPE, ImportMetadataMap } from '@/imports/@types/dataImporter/imports'

export const useDataImporterApi = (http: AxiosInstance) => ({
  getJwtToken<Type extends FILE_IMPORT_TYPE>(
    importType: Type,
    metadata: Omit<ImportMetadataMap[Type], 'importType' | 'user_id'>,
  ) {
    return http.post(
      POST_DATA_IMPORTER_JWT_TOKEN_URI,
      { importType, metadata },
      { headers: { 'x-org-id': metadata.orgId } },
    )
  },
})
