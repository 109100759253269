import { IsOptional, IsString, IsUUID, IsBoolean } from 'class-validator'

import { ValidateDTO } from '..'

import { DATA_SOURCE_TYPES } from '../../../../imports/@enums/data-source.enums'

import { DATASOURCE_CONTROLLER } from './controller.common-vars'

export const PRODUCT_DATA_SOURCE_CREATE_SUBPATH = 'product'
export const PRODUCT_DATA_SOURCE_CREATE_URI = `${DATASOURCE_CONTROLLER}/${PRODUCT_DATA_SOURCE_CREATE_SUBPATH}`

export class CreateProductDataSourceDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsString()
  @IsOptional()
  targetModule?: DATA_SOURCE_TYPES

  @IsBoolean()
  @IsOptional()
  useDataImporter?: boolean
}

export type CreateProductDataSourceDTORes = {
  dataSources: string[]
}
