import { PRODUCT_FOOTPRINTS_CONTROLLER } from '../controller.common-vars'

import { ValidateDTO } from '../..'

// Category names for filtering categories
// [GET] /products/footprints/overview/categories/names
export const PRODUCT_CATEGORIES_NAMES_SUBPATH = 'overview/categories/names'
export const PRODUCT_CATEGORIES_NAMES_URI = `${PRODUCT_FOOTPRINTS_CONTROLLER}/${PRODUCT_CATEGORIES_NAMES_SUBPATH}`

export class CategoryNamesDTOReq extends ValidateDTO {
  startMonth!: string

  endMonth!: string
}

export type CategoryNamesDTORes = {
  categories: string[]
}
