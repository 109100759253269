import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { PRODUCTS_CONTROLLER } from './controller.common-vars'

export const GET_PRODUCT_DATASOURCE_TEMPLATE_SUBPATH = 'aggregates'
export const GET_PRODUCT_DATASOURCE_TEMPLATE_URI = `${PRODUCTS_CONTROLLER}/${GET_PRODUCT_DATASOURCE_TEMPLATE_SUBPATH}`

export class ProductDataSourceTemplateDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  rootOrgId!: string
}
