import type { AxiosInstance } from 'axios'
import { replaceUriParams } from '@lib/DTO'

import {
  GET_SUPPLIERS_PATH,
  type GetSuppliersDTO,
  type GetSuppliersDTORes,
} from '@lib/DTO/suppliersv2/get-suppliers.dto'
import {
  ACCEPT_SUPPLIER_INVITE_PATH,
  type AcceptInviteDTO,
  type AcceptSupplierInviteDTORes,
} from '@lib/DTO/suppliersv2/accept-invite.dto'
import { GET_SUPPLIER_INVITE_PATH, type GetSupplierInviteDTORes } from '@lib/DTO/suppliersv2/get-supplier-invite.dto'
import { RESEND_SUPPLIER_INVITE_PATH, type ResendInviteDTORes } from '@lib/DTO/suppliersv2/resend-invite.dto'
import {
  UPDATE_INVITE_EMAIL_PATH,
  type UpdateInviteEmailDTO,
  type UpdateInviteDTORes,
} from '@lib/DTO/suppliersv2/update-invite-email.dto'
import { GET_SUPPLIER_PATH, type GetSupplierDTO, type GetSupplierDTORes } from '@lib/DTO/suppliersv2/get-supplier.dto'
import {
  EDIT_SUPPLIER_PATH,
  type EditSupplierDTO,
  type EditSupplierDTORes,
} from '@lib/DTO/suppliersv2/edit-supplier-associations.dto'
import {
  MERGE_SUPPLIERS_PATH,
  type MergeSuppliersDTO,
  type MergeSuppliersDTORes,
} from '@lib/DTO/suppliersv2/merge-suppliers.dto'
import {
  GET_SUPPLIER_ASSOCIATIONS_PATH,
  type GetSupplierAssociationsDTO,
  type GetSupplierAssociationsDTORes,
} from '@lib/DTO/suppliersv2/get-supplier-associations.dto'

export const useSuppliersApi = (http: AxiosInstance) => ({
  getSuppliers(params: GetSuppliersDTO) {
    return http.get<GetSuppliersDTORes>(GET_SUPPLIERS_PATH, { params })
  },

  acceptInvite(payload: AcceptInviteDTO) {
    const { token, user, organization } = payload

    return http.post<AcceptSupplierInviteDTORes>(replaceUriParams(ACCEPT_SUPPLIER_INVITE_PATH, { token }), {
      user,
      organization,
    })
  },

  getInvite(token: string) {
    return http.get<GetSupplierInviteDTORes>(replaceUriParams(GET_SUPPLIER_INVITE_PATH, { token }))
  },

  resendInvite(inviteId: string) {
    return http.post<ResendInviteDTORes>(replaceUriParams(RESEND_SUPPLIER_INVITE_PATH, { inviteId }))
  },

  updateInviteEmail(inviteId: string, payload: UpdateInviteEmailDTO) {
    return http.patch<UpdateInviteDTORes>(replaceUriParams(UPDATE_INVITE_EMAIL_PATH, { inviteId }), payload)
  },

  getSupplier(supplierId: string, params: GetSupplierDTO) {
    return http.get<GetSupplierDTORes>(replaceUriParams(GET_SUPPLIER_PATH, { supplierId }), { params })
  },

  getAssociations(supplierId: string, params: GetSupplierAssociationsDTO) {
    return http.get<GetSupplierAssociationsDTORes>(replaceUriParams(GET_SUPPLIER_ASSOCIATIONS_PATH, { supplierId }), {
      params,
    })
  },

  editSupplier(supplierId: string, payload: EditSupplierDTO) {
    return http.patch<EditSupplierDTORes>(replaceUriParams(EDIT_SUPPLIER_PATH, { supplierId }), payload)
  },

  mergeSuppliers(payload: MergeSuppliersDTO) {
    return http.post<MergeSuppliersDTORes>(MERGE_SUPPLIERS_PATH, payload)
  },
})
