import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { PRODUCTS_CONTROLLER } from './controller.common-vars'

export const GET_PRODUCTS_IDS_SUBPATH = 'ids'
export const GET_PRODUCTS_IDS_URI = `${PRODUCTS_CONTROLLER}/${GET_PRODUCTS_IDS_SUBPATH}`

export class ProductsIdsDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string
}

export type ProductsIdsDTORes = {
  ids: string[]
}
