// Highest emitting product
// [GET] /products/footprints/overview/metric-highest-product?orgId=xxx&startMonth=01-2022&endMonth=12-2022

import { PRODUCT_FOOTPRINTS_CONTROLLER } from '../controller.common-vars'

export const PRODUCT_OVERVIEW_HIGHEST_PRODUCT_SUBPATH = 'overview/metric-highest-product'
export const PRODUCT_OVERVIEW_HIGHEST_PRODUCT_URI = `${PRODUCT_FOOTPRINTS_CONTROLLER}/${PRODUCT_OVERVIEW_HIGHEST_PRODUCT_SUBPATH}`

export class HighestEmittingProductDTOReq {
  startMonth!: string

  endMonth!: string
}

export type HighestEmittingProductDTORes = {
  productName: string
  totalEmissions: number
  totalEmissionUnit: string // changed from emissionsUnit
}
