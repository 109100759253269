import { ArrayMaxSize, ArrayMinSize, IsArray, IsString } from 'class-validator'

import { type SupplierModel } from '../../../@types/suppliers.types'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class MergeSuppliersDTO {
  @IsString()
  name!: string

  @IsString()
  email!: string

  @IsString({ each: true })
  @IsArray()
  @ArrayMaxSize(250)
  @ArrayMinSize(2)
  supplierIds!: string[]
}

export type MergeSuppliersDTORes = {
  supplier: SupplierModel
}

export const MERGE_SUPPLIERS_SUB_PATH = 'merge'
export const MERGE_SUPPLIERS_PATH = `${SUPPLIERS_CONTROLLER}/${MERGE_SUPPLIERS_SUB_PATH}`
