import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { DATASOURCE_CONTROLLER } from './controller.common-vars'

import { DATA_SOURCE_TARGET_MODULE } from '@/imports/@enums/data-source.enums'

export const PRODUCT_DATA_SOURCES_GET_SUBPATH = 'product'
export const PRODUCT_DATA_SOURCES_GET_URI = `${DATASOURCE_CONTROLLER}/${PRODUCT_DATA_SOURCES_GET_SUBPATH}`

export class GetProductDataSourcesDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string
}

export type GetProductDataSourcesDTORes = {
  dataSources: {
    id: string
    status: string
    calculationMethods: string[]
    assumptions: string[]
    isCustomCalculation: boolean
    orgId: string
    name: string
    startDate: string
    endDate: string | null
    updateFrequency: string
    type: string
    lookupName: string
    description: string
    targetModule: DATA_SOURCE_TARGET_MODULE
    updatedAt: string
    createdAt: string
    asanaTaskId: string
    labelStudioProjectId: string
    deletedAt: string
    osTemplateKey: string
    osTemplate: {
      templateKey: string
      config: unknown
    }
    fileCount: number
  }[]
  facilities: {
    count: number
  }
  products: {
    count: number
    updatedAt: string
  }
}
