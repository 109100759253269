import { IsUUID } from 'class-validator'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class ResendInviteDTO {
  @IsUUID()
  inviteId!: string
}

export type ResendInviteDTORes = {
  success: boolean
  email: string
}

export const RESEND_SUPPLIER_INVITE_SUB_PATH = 'invites/:inviteId/resend'
export const RESEND_SUPPLIER_INVITE_PATH = `${SUPPLIERS_CONTROLLER}/${RESEND_SUPPLIER_INVITE_SUB_PATH}`
