import { defineStore } from 'pinia'

import { showToast } from '@lib/plugins/Toastie'

import type { AddFranchiseDTO } from '@lib/DTO/franchises/add-franchise.dto'

import { getApiErrorMessage } from '@lib/utilities/getApiErrorMessage'

import type { EditFranchiseDTO } from '@lib/DTO/franchises/edit-franchise.dto'
import type { GetFranchiseRequestsRespondedPercentageResponse } from '@lib/DTO/franchise-requests/get-franchise-requests-responded-percentage.dto'
import type { GetFranchiseRequestProgressMetaDTORes } from '@lib/DTO/franchise-requests/get-progress-meta.dto'

import { SORT_ORDER } from '@/imports/@enums/common.enums'

import { FRANCHISE_STATUS, GET_FRANCHISES_SORT_FIELD } from '@/imports/@enums/franchises.enums'

import { Api } from '@/imports/lib/services/api.service'

import type { IFranchise } from '@/imports/@types/franchises.types'
import type { GetFranchisesDTO } from '@/imports/lib/DTO/franchises/get-franchises.dto'
import { useOrganizationStore } from '@/client/store/organization.pinia'

const INITIAL_FRANCHISES_COUNT_STATS = {
  totalRequestsCount: 0,
  completedRequestsCount: 0,
}

type State = {
  states: {
    isLoadingFranchises: boolean
    isCreatingFranchise: boolean
    isLoadingFranchisesDataRequest: boolean
    isDeletingFranchise: boolean
    isUpdatingFranchise: boolean
    isLoadingMeasurementProgressDetails: boolean
  }
  abortControllers: {
    loadingFranchises: AbortController | null
  }
  meta: {
    totalCount: number
    perPage: number
    pageNo: number
    includeAll: boolean
  }
  paginationMeta: {
    sortOrder: SORT_ORDER
    sortField: GET_FRANCHISES_SORT_FIELD
  }
  filters: {
    searchQuery: string
    countries: string[]
    statuses: FRANCHISE_STATUS[]
    hasProgressMetrics: boolean
    year?: number
  }
  franchises: IFranchise[]
  measurementProgressDetailStats: GetFranchiseRequestsRespondedPercentageResponse
  dataRequestsStats: GetFranchiseRequestProgressMetaDTORes
  errors: {
    loadingDataRequests: string
    loadingDataRequestsStats: string
  }
}

export const useFranchisesStore = defineStore('franchises', {
  state: (): State => ({
    franchises: [],
    meta: {
      totalCount: 0,
      perPage: 0,
      pageNo: 1,
      includeAll: false,
    },
    paginationMeta: {
      sortOrder: SORT_ORDER.ASC,
      sortField: GET_FRANCHISES_SORT_FIELD.NAME,
    },
    filters: {
      searchQuery: '',
      countries: [],
      statuses: [],
      hasProgressMetrics: true,
    },
    states: {
      isLoadingFranchises: false,
      isCreatingFranchise: false,
      isLoadingFranchisesDataRequest: false,
      isDeletingFranchise: false,
      isUpdatingFranchise: false,
      isLoadingMeasurementProgressDetails: false,
    },
    abortControllers: {
      loadingFranchises: null,
    },
    dataRequestsStats: INITIAL_FRANCHISES_COUNT_STATS,
    measurementProgressDetailStats: {
      percentage: 0,
      totalCount: 0,
      respondedCount: 0,
    },
    errors: {
      loadingDataRequests: '',
      loadingDataRequestsStats: '',
    },
  }),

  actions: {
    async getFranchises() {
      if (this.abortControllers.loadingFranchises) {
        this.abortControllers.loadingFranchises.abort()
      }

      this.states.isLoadingFranchises = true

      this.abortControllers.loadingFranchises = new AbortController()

      const payload: GetFranchisesDTO = {
        ...this.filters,
        ...this.paginationMeta,
        pageNo: this.meta.pageNo,
        statuses: this.filters.statuses,
        perPage: this.meta.perPage ? this.meta.perPage : 10,
        includeAll: this.meta.includeAll,
      }

      if (this.filters.year) {
        payload.inviteYear = this.filters.year
      }

      try {
        const { data } = await Api.franchises.getFranchises(payload, this.abortControllers.loadingFranchises.signal)

        this.meta = {
          ...this.meta,
          ...data.meta,
        }

        this.franchises = data.franchises
      } catch (err) {
        if (typeof err === 'object' && err !== null && 'name' in err) {
          if (err.name === 'CanceledError') {
            return
          }
        }

        console.error(getApiErrorMessage(err))

        showToast({
          type: 'is-danger',
          message: getApiErrorMessage(err),
        })
      } finally {
        this.states.isLoadingFranchises = false
        this.abortControllers.loadingFranchises = null
      }
    },

    async createFranchise(payload: AddFranchiseDTO) {
      this.states.isCreatingFranchise = true

      try {
        await Api.franchises.createFranchise(payload)

        showToast({
          type: 'is-success',
          message: `Franchise '${payload.name}' created successfully`,
        })

        await this.getFranchises()
      } catch (err) {
        console.error(getApiErrorMessage(err))

        showToast({
          type: 'is-danger',
          message: getApiErrorMessage(err),
        })
      } finally {
        this.states.isCreatingFranchise = false
      }
    },

    updateYear(year: number) {
      this.filters.year = year
    },

    async deleteFranchise(franchiseId: string) {
      this.states.isDeletingFranchise = true

      try {
        await Api.franchises.deleteFranchise(franchiseId)

        showToast({
          type: 'is-success',
          message: `Franchise deleted successfully`,
        })

        this.meta.pageNo = 1
        await this.getFranchises()
      } catch (err) {
        console.error(getApiErrorMessage(err))

        showToast({
          type: 'is-danger',
          message: getApiErrorMessage(err),
        })
      } finally {
        this.states.isDeletingFranchise = false
      }
    },

    async updateFranchise(payload: EditFranchiseDTO, franchiseId: string) {
      this.states.isUpdatingFranchise = true
      try {
        await Api.franchises.updateFranchise(payload, franchiseId)

        showToast({
          type: 'is-success',
          message: `Franchise '${payload.name}' updated successfully`,
        })

        await this.getFranchises()
      } catch {
        showToast({
          type: 'is-danger',
          message: 'Failed to update franchise. Please try again later.',
        })
      } finally {
        this.states.isUpdatingFranchise = false
      }
    },

    async updateSearchQuery(query: string) {
      this.filters.searchQuery = query
      this.meta.pageNo = 1
      await this.getFranchises()
    },

    async updateSortOrder(sortOrder: SORT_ORDER) {
      this.paginationMeta.sortOrder = sortOrder
    },

    async updateSortField(sortField: GET_FRANCHISES_SORT_FIELD) {
      this.paginationMeta.sortField = sortField
      await this.getFranchises()
    },

    async updateCountryFilter(countries: string[]) {
      this.filters.countries = countries
      this.meta.pageNo = 1
      await this.getFranchises()
    },

    async updateStatusFilter(statuses: FRANCHISE_STATUS[]) {
      this.filters.statuses = statuses
      this.meta.pageNo = 1

      await this.getFranchises()
    },

    async updatePageNo(number: number) {
      this.meta.pageNo = number

      await this.getFranchises()
    },

    async getDataRequestsStats() {
      this.states.isLoadingFranchisesDataRequest = true
      this.errors.loadingDataRequestsStats = ''

      const orgStore = useOrganizationStore()
      const orgId = orgStore.activeOrganization?.id

      if (!orgId) throw new Error('/franchise-data-requests/stats >>> Please provide a valid orgId')

      try {
        const { data } = await Api.franchises.getStats({
          orgId,
        })

        this.dataRequestsStats = data
      } catch (err) {
        console.error(err)

        const errorMessage = 'Failed to receive data requests stats. Please try again later.'

        showToast({
          type: 'is-danger',
          message: errorMessage,
        })

        this.errors.loadingDataRequestsStats = errorMessage
      }

      this.states.isLoadingFranchisesDataRequest = false
    },

    async getMeasurementProgressDetailStats(year: number) {
      try {
        this.states.isLoadingMeasurementProgressDetails = true

        if (!year) return

        const { data } = await Api.franchises.getPercentageShared({ year })

        this.measurementProgressDetailStats = data
      } catch (err) {
        console.error(err)

        const errorMessage = 'Failed to receive percentage for shared data. Please try again later.'

        showToast({
          type: 'is-danger',
          message: errorMessage,
        })

        this.measurementProgressDetailStats = {
          percentage: 0,
          totalCount: 0,
          respondedCount: 0,
        }
      } finally {
        this.states.isLoadingMeasurementProgressDetails = false
      }
    },
  },
})
