import { Type } from 'class-transformer'
import { IsString, ValidateNested } from 'class-validator'

import { ValidateDTO } from '../..'

import { FILE_IMPORT_TYPE } from '../../../../@types/dataImporter/imports'

import { ONE_SCHEMA_AUTH_CONTROLLER } from './controller.common-vars'
import { CorporateDataSourceImportMetadata } from './corporate.dto'
import { ProductDataSourceImportMetadata } from './product.dto'
import { EmissionFactorImportMetadata } from './ef.dto'
import { EmissionFactorSourceImportMetadata } from './ef-source.dto'
import { ImportMetadata } from './metadata.dto'

export const POST_DATA_IMPORTER_JWT_TOKEN_SUBPATH = 'jwt/generate'
export const POST_DATA_IMPORTER_JWT_TOKEN_URI = `${ONE_SCHEMA_AUTH_CONTROLLER}/${POST_DATA_IMPORTER_JWT_TOKEN_SUBPATH}`

export class PostDataImporterJWTTokenDTO extends ValidateDTO {
  @IsString()
  importType!: FILE_IMPORT_TYPE

  @ValidateNested()
  @Type(() => ImportMetadata, {
    discriminator: {
      property: 'importType',
      subTypes: [
        { value: CorporateDataSourceImportMetadata, name: FILE_IMPORT_TYPE.CORPORATE_DATA_SOURCE },
        { value: ProductDataSourceImportMetadata, name: FILE_IMPORT_TYPE.PRODUCT_DATA_SOURCE },
        { value: EmissionFactorImportMetadata, name: FILE_IMPORT_TYPE.EMISSION_FACTOR },
        { value: EmissionFactorSourceImportMetadata, name: FILE_IMPORT_TYPE.EMISSION_FACTOR_SOURCE },
      ],
    },
  })
  metadata?: ImportMetadata
}
