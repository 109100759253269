import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { PRODUCT_FOOTPRINTS_CONTROLLER } from './controller.common-vars'

export const GET_PRODUCT_FOOTPRINT_AGGREGATES_SUBPATH = 'aggregates'
export const GET_PRODUCT_FOOTPRINT_AGGREGATES_URI = `${PRODUCT_FOOTPRINTS_CONTROLLER}/${GET_PRODUCT_FOOTPRINT_AGGREGATES_SUBPATH}`

export class ProductFootPrintAggregatesDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string
}
