import { CALC_METHOD } from '../../../../@enums/calcMethodCode.enum'

import { ImportMetadata } from './metadata.dto'

export class CorporateDataSourceImportMetadata extends ImportMetadata {
  rootOrgId!: string

  orgId!: string

  dataSourceId!: string

  calcMethodCode?: CALC_METHOD
}
