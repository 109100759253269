import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { PRODUCTS_CONTROLLER } from './controller.common-vars'

export const GET_PRODUCT_LIST_CATEGORIES_SUBPATH = 'categories'
export const GET_PRODUCT_LIST_CATEGORIES_URI = `${PRODUCTS_CONTROLLER}/${GET_PRODUCT_LIST_CATEGORIES_SUBPATH}`

export class GetProductListCategoriesDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string
}

export type GetProductListCategoriesDTORes = {
  categories: string[]
}
