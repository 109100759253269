import { IsEnum, IsOptional } from 'class-validator'

import { PRODUCT_FOOTPRINTS_CONTROLLER } from '../controller.common-vars'

import { ValidateDTO } from '../..'
import type { PaginationMeta } from '../../generic/pagination-meta.dto'

import { SORT_ORDER } from '../../../../@enums/common.enums'

export enum PRODUCT_LIST_SORT_FIELD {
  NAME = 'name',
  TOTAL_EMISSIONS = 'totalEmissions',
  TOTAL_PRODUCTS_PRODUCED = 'totalProductsProduced',
  EMISSIONS_PER_PRODUCT = 'emissionsPerProduct',
  TOTAL_WEIGHT = 'totalWeight',
  EMISSIONS_PER_WEIGHT = 'emissionsPerWeightUnit',
}

// Product list page
// [GET] /products/overview?searchTerm=foo&category=bar&perPage=10&pageNo=1
export const PRODUCT_OVERVIEW_SUBPATH = 'overview'
export const PRODUCT_OVERVIEW_URI = `${PRODUCT_FOOTPRINTS_CONTROLLER}/${PRODUCT_OVERVIEW_SUBPATH}`

export class ProductListDTOReq extends ValidateDTO {
  startMonth!: string

  endMonth!: string

  searchTerm?: string

  category?: string

  perPage?: number

  pageNo?: number

  @IsOptional()
  @IsEnum(PRODUCT_LIST_SORT_FIELD)
  sortBy?: PRODUCT_LIST_SORT_FIELD

  @IsOptional()
  @IsEnum(SORT_ORDER)
  sortOrder?: SORT_ORDER
}

export type SingleProductOverview = {
  id: string
  name: string
  category: string
  UOM: string
  totalWeight: number
  totalWeightUnit: string
  totalProductsProduced: number
  totalEmissions: number
  totalEmissionUnit: string // changed from emissionsUnit
  singleProductWeight: number // totalWeight/totalProductsProduced
  singleProductWeightUnit: string
  emissionsPerWeightUnit: number // totalEmissions/totalWeight
  emissionsPerProduct: number // totalEmissions/totalProductsProduced
}

export type ProductListDTORes = {
  products: SingleProductOverview[]
  meta: PaginationMeta
}
