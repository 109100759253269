import { FRANCHISES_CONTROLLER } from './franchises.common-vars'

export const GET_FRANCHISES_MEASUREMENT_PROGRESS_SUB_PATH = 'measurement-progress'
export const GET_FRANCHISES_MEASUREMENT_PROGRESS_PATH = `${FRANCHISES_CONTROLLER}/${GET_FRANCHISES_MEASUREMENT_PROGRESS_SUB_PATH}`

export type GetFranchisesMeasurementProgressDTORes = {
  totalEmissions: number
  totalFranchises: number
  totalIngestedFranchises: number
  totalIngestedEstimations: number
}
