import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { PRODUCT_FOOTPRINTS_CONTROLLER } from './controller.common-vars'

export const GET_PRODUCT_FOOTPRINT_CATEGORIES_SUBPATH = 'categories'
export const GET_PRODUCT_FOOTPRINT_CATEGORIES_URI = `${PRODUCT_FOOTPRINTS_CONTROLLER}/${GET_PRODUCT_FOOTPRINT_CATEGORIES_SUBPATH}`

export class ProductFootPrintCategoriesDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string
}
